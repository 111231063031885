/// <reference path="../typings/index.d.ts" />
namespace App.Modulos {
    export class Categoria {
        public static inicializar() {
            var categoria = new App.Categoria();
            categoria.inicializarEventos();
        }
    }
}

namespace App {
    export class Categoria {

        public inicializarEventos() {
            var modelView = new ModelView();
            modelView.inicializar($(".form-categoria"));
        }
    }
}
