/// <reference path="../typings/index.d.ts" />
/// <reference path="CampoSwitchery.d.ts" />

namespace App {
    export class CampoSwitchery implements ICampoSwitchery {

        public inicializarCampo(seletor: string, cor: string): any {
            var elemento = document.querySelector(seletor);
            var campoSwitchery = new Switchery(elemento, { color: cor });
            return campoSwitchery;
        }

        public changeSwitchery(element: any, checked: boolean) {
            if ((element.is(':checked') && checked == false) || (!element.is(':checked') && checked == true)) {
                element.parent().find('.pcdSwitchery').trigger('click');
            }
        }
    }
}
