/// <reference path="../typings/index.d.ts" />
/// <reference path="CampoChosen.d.ts" />

namespace App {
    export class CampoChosen implements ICampoChosen {
        public seletorFormulario: string;
        public seletorCampo: string;
        public seletorProximoCampo: string = "";
        public seletorAnteriorCampo: string = "";
        public quantidadeLimiteItens: number = 4;
        public mensagemDadosNaoEncontrados: string = "Item não encontrado";
        public executarMetodoAposLimiteNaoPermitido: any;
        public campoWidth: string = "100%";

        private static personalizarEstiloAoModificarCampo(campo: JQuery, selectform: string): void {
            if (App.Util.ehCampoTextoVazio(campo)) {
                $(selectform + ' .label-chosen').hide();
            } else {
                $(selectform + ' .label-chosen').show();
            }
        }
        public inicializarCampo(campoNameOculto: string): void {
            var campoFocus = $(this.seletorFormulario + " " + this.seletorProximoCampo);
            var seletorFormulario = this.seletorFormulario;
            $(this.seletorCampo).chosen({ width: this.campoWidth, max_selected_options: this.quantidadeLimiteItens, disable_search_threshold: this.quantidadeLimiteItens, no_results_text: this.mensagemDadosNaoEncontrados })
                .change(function(event) {
                    if (event.target == this) {
                        $('input[name=' + campoNameOculto + ']').val($(this).val());
                        App.CampoChosen.personalizarEstiloAoModificarCampo($('input[name=' + campoNameOculto + ']'), seletorFormulario);
                    }
                });

            $(this.seletorCampo).bind("chosen:maxselected", () => {
                if (campoFocus.length > 0) {
                    $(campoFocus).focus();
                }
                this.executarMetodoAposLimiteNaoPermitido();
            });
            $(".search-field input").on('keydown', (e) => {
                var keyCode = e.keyCode || e.which;

                if (keyCode == 9) {
                    campoFocus.focus();
                    e.preventDefault();
                }
            });
        }

    }
}
