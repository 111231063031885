/// <reference path="../typings/index.d.ts" />
/// <reference path="AlertaNotificacaoToastr.d.ts" />

namespace App {
    export class AlertaNotificacaoToastr implements IAlertaNotificacaoToastrOptions {
        public closeButton = true;
        public debug = false;
        public progressBar = true;
        public preventDuplicates = false;
        public positionClass = "toast-bottom-right";
        public onclick = "";
        public showDuration = "400";
        public hideDuration = "1000";
        public timeOut = "7000";
        public extendedTimeOut = "1000";
        public showEasing = "swing";
        public hideEasing = "linear";
        public showMethod = "show";
        public hideMethod = "fadeOut";

        public inicializarComponente(): void {
            toastr.options = this;
        }

        public exibirInfo(titulo: string, mensagem: string): void {
            toastr.info(mensagem, titulo);
        }
        public exibirErro(titulo: string, mensagem: string): void {
            toastr.error(mensagem, titulo);
        }
        public exibirSucesso(titulo: string, mensagem: string): void {
            toastr.success(mensagem, titulo);
        }
        public exibirAtencao(titulo: string, mensagem: string): void {
            toastr.warning(mensagem, titulo)
        }

    }
}
