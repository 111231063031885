/// <reference path="../typings/index.d.ts" />
namespace App.Modulos {
    export class ConfiguracaoHabilitarDesabilitar {
        public static inicializar() {
            var configuracaoHabilitarDesabilitar = new App.ConfiguracaoHabilitarDesabilitar();
            configuracaoHabilitarDesabilitar.inicializarEventos();
        }
    }
}

namespace App {
    export class ConfiguracaoHabilitarDesabilitar {
        private ajax: IAjax;
        private elAtivarModoHumano: JQuery = $("#ativarModoHumano");
        private elAtivarPotenciaJogadorEspecial: JQuery = $("#ativarPotenciaJogadorEspecial");
        public inicializarEventos() {
            this.ajax = new App.Ajax();
            this.elAtivarModoHumano.on('change', (e) => { this.aoSelecionarModoHumano(e); });
            this.elAtivarPotenciaJogadorEspecial.on('change', (e) => { this.aoSelecionarPotenciaJogadorEspecial(e); });
        }

        private aoSelecionarModoHumano(e: JQueryEventObject) {
            if ($(e.target).is(':checked')) {
                this.ajax.postAcaoSemResposta($(e.target).data('url'), { modo_humano: $(e.target).is(':checked') });
            } else {
                swal({
                    title: 'Tem certeza disso?',
                    text: "O modo humano é uma forma de deixar as ações do FUTSNIPERWEB menos robótica para evitar despertar a atenção da EA em sua conta",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#db7250',
                    cancelButtonColor: '#2dce89',
                    confirmButtonText: 'Sim, quero mais liberdade',
                    cancelButtonText: "manter ativo"
                }).then((result: any) => {
                    if (result.value) {
                        this.ajax.postAcaoSemResposta($(e.target).data('url'), { modo_humano: false });
                    } else {
                        $(e.target).prop("checked", true);
                    }
                })
            }
        }
        private aoSelecionarPotenciaJogadorEspecial(e: JQueryEventObject) {
            if ($(e.target).is(':checked')) {
                swal({
                    title: 'Tem certeza disso?',
                    text: "Quando habilitado, Você poderá snipar cartas especiais, essa é uma funcionalidade com maior risco para sua conta",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#db7250',
                    cancelButtonColor: '#2dce89',
                    confirmButtonText: 'Sim, estou ciente dos riscos.',
                    cancelButtonText: "manter desabilitado"
                }).then((result: any) => {
                    if (result.value) {
                        this.ajax.postAcaoSemResposta($(e.target).data('url'), { potencializar_busca_jogador_especial: true });
                        setTimeout(() => {
                            window.location.href = "/app/jogador-especial";
                        }, 1000);
                    } else {
                        $(e.target).prop("checked", false);
                    }
                })
            } else {
                this.ajax.postAcaoSemResposta($(e.target).data('url'), { potencializar_busca_jogador_especial: $(e.target).is(':checked') });
            }
        }
    }
}
