/// <reference path="../typings/index.d.ts" />
declare var _gaq: any;
declare var ga: any;

namespace App {
    export class EventosAnalytics {
        public static dispararTrackEvent(category: string, action: any, label?: any, value?: any) {
            if (typeof (_gaq) != "undefined") {
                var arrAuxiliar = ['_trackEvent', category, action];
                if (label !== undefined) {
                    arrAuxiliar.push(label);
                }
                if (value !== undefined) {
                    arrAuxiliar.push(value);
                }
                _gaq.push(arrAuxiliar);
            } else if (typeof (ga) != "undefined") {
                ga('send', 'event', category, action, label, value);
            }
        }

        public static registrarAoClicar(elementoJQuery: JQuery, category: string, label?: any) {
            elementoJQuery.on('click', function() {
                EventosAnalytics.dispararTrackEvent(category, 'click', label);
            });
        }
        
        public static registrarAoAbrirModal(elementoJQuery: JQuery, category: string, label?: any) {
            elementoJQuery.on('shown.bs.modal', function(e) {
                EventosAnalytics.dispararTrackEvent(category, 'openModal', label);
            });
        }

        public static registrarAoFocar(elementoJQuery: JQuery, category: string, label?: any) {
            elementoJQuery.on('focus', function() {
                EventosAnalytics.dispararTrackEvent(category, 'focus', label);
            });
        }

        public static registrarAoTrocar(elementoJQuery: JQuery, category: string, label?: any) {
            elementoJQuery.on('change', function() {
                EventosAnalytics.dispararTrackEvent(category, 'change', label);
            });
        }

        public static registrarAoMouseOver(elementoJQuery: JQuery, category: string, label?: any) {
            elementoJQuery.on('mouseover', function() {
                EventosAnalytics.dispararTrackEvent(category, 'mouseover', label);
            });
        }
    }
}
