/// <reference path="../typings/index.d.ts" />
/// <reference path="Video.d.ts" />

namespace App {
    export class Video implements IVideo {

        public inicializar(elemento: JQuery) {
            elemento.on("click", (e) => {
                e.preventDefault();
                var id = $(e.currentTarget).data('youtubeId');
                var autoplay = '?autoplay=1';
                var related_no = '&rel=0';
                var src = '//www.youtube.com/embed/' + id + autoplay + related_no;
                $("#youtube").attr('src', src);
                $("body").addClass("show-video-modal noscroll");
            });

            $('body').on('click', '.close-video-modal, .video-modal .overlay', (e: JQueryEventObject) => {
                this.fecharVideoModal(e);
            });

            $('body').keyup((e: JQueryEventObject) => {
                if (e.keyCode == 27) {
                    this.fecharVideoModal(e);
                }
            });
        }

        public fecharVideoModal(e: JQueryEventObject) {
            e.preventDefault();
            $("body").removeClass("show-video-modal noscroll");
            $("#youtube").attr('src', '');
        }
    }
}

