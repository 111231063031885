/// <reference path="../typings/index.d.ts" />
namespace App.Modulos {
    export class Site {
        public static inicializar() {
            var site = new App.Site();
            site.inicializarEventos();
        }
    }
}

namespace App {
    export class Site {
        private ajax: IAjax;

        public inicializarEventos() {
        }

    }
}
