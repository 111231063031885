/// <reference path="../typings/index.d.ts" />
/// <reference path="CampoUpload.d.ts" />

namespace App {
    export class CampoUpload implements ICampoUpload {
        private dropzone: any;

        public inicializarCampo(elementoCampo: any, modal: any, metodoCallbackSucess: any, metodoCallbackError: any = null, metodoCallbackAddFile: any = null, metodoCallbackComplete: any = null): void {
            Dropzone.autoDiscover = false;
            this.dropzone = new Dropzone(elementoCampo, {
                maxFilesize: 2,
                init: function() {
                    this.on("success", metodoCallbackSucess);
                    if (metodoCallbackError) {
                        this.on("error", metodoCallbackError);
                    }
                    
                    if (metodoCallbackAddFile) {
                        this.on("addedfile", metodoCallbackAddFile);
                    }
                    
                    if (metodoCallbackComplete) {
                        this.on("complete", metodoCallbackComplete);
                    }

                    this.on("processing", function() {
                        if (modal) {
                            modal.modal('hide');
                        }
                        App.Util.exibirModalPaginaCarregando();
                    });
                }
            });
        }
    }
}
