/// <reference path="../typings/index.d.ts" />
namespace App.Modulos {
    export class Produto {
        public static inicializar() {
            var produto = new App.Produto();
            produto.inicializarEventos();
        }
    }
}

namespace App {
    export class Produto {

        public inicializarEventos() {
            var modelView = new ModelView();
            modelView.inicializar($(".form-produto"));
        }
    }
}
