/// <reference path="../typings/index.d.ts" />
/// <reference path="ModelView.d.ts" />
namespace App {
    export class ModelView implements IModelView {
        private ajax: IAjax;
        private elBtnExcluir: JQuery = $(".btn-excluir");
        private elBtnAtivarDesativar: JQuery = $(".btn-ativar-desativar");
        public static acaoAposExclusao: any = null;
        public textoConfirmacaoExclusao: string = 'Essa ação não poderá ser revertida.';
        public pedirConfirmacaoExclusao: boolean = true;

        public inicializar(elFom: JQuery = null, validar: boolean = false, acaoAposExclusao: any = null) {
            if (elFom) {
                elFom.on('submit', (e) => { this.aoSalvarRegistroPorModal(e, validar); });
            }
            this.elBtnExcluir.on("click", (e) => { this.aoExcluirRegistro(e) });
            this.elBtnAtivarDesativar.on("click", (e) => { this.aoAtivarDesativarRegistro(e) });
            App.ModelView.acaoAposExclusao = acaoAposExclusao;
        }

        public inicializarBotaoExcluir(btnExcluir: JQuery) {
            btnExcluir.on("click", (e) => { this.aoExcluirRegistro(e) });
        }

        public aoSalvarRegistroPorModal(e: JQueryEventObject, validar: boolean = false) {
            var seletorModalContexto: string = "#" + $(e.target).parents('.modal').attr('id');
            if (validar && !$(e.target).valid()) {
                e.preventDefault();
                return;
            }
            this.ajax = new App.Ajax();
            this.ajax.postModal(e, seletorModalContexto);
        }

        public aoAtivarDesativarRegistro(e: JQueryEventObject) {
            this.ajax = new App.Ajax();
            this.ajax.postAcao($(e.currentTarget).data("url"), { id: $(e.currentTarget).data("id") });
        }

        public aoExcluirRegistro(e: JQueryEventObject) {
            if (this.pedirConfirmacaoExclusao) {
                App.Util.exebirSweetConfirmacao(e, "Você tem certeza disso?", this.textoConfirmacaoExclusao, "Sim, continuar", this.excluirRegistro);
                return;
            }
            this.excluirRegistro(e);
        }

        private excluirRegistro(e: JQueryEventObject) {
            this.ajax = new App.Ajax();
            this.ajax.postAcaoSemCabecalhoGlobal($(e.currentTarget).data("url"), { id: $(e.currentTarget).data("id") }, App.ModelView.acaoAposExclusao, e);
        }
    }

}
