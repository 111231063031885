/// <reference path="../typings/index.d.ts" />
/// <reference path="Ajax.d.ts" />
namespace App {
    export class Ajax implements IAjax {
        public url: string;
        public urlAutenticado: string = "/autenticado";

        public post(e: JQueryEventObject, callbackPreProcesso?: any, callbackSucesso?: any, callbackErro?: any, callbackCompleto?: any) {
            e.preventDefault();
            if (callbackPreProcesso) {
                callbackPreProcesso(e);
            } else {
                App.Util.exibirModalPaginaCarregando();
            }
            var url: string = $(e.target).attr("action");
            var dados: any = this.convertFormToJSON($(e.target));
            App.Util.preparaCabecalhoRequisicaoAjax();
            $.post(url, JSON.stringify(dados))
                .done((dados, status, jqXHR) => {
                    if (callbackSucesso) {
                        callbackSucesso(e, dados, status, jqXHR);
                    } else {
                        if (dados.recarregar == true) {
                            window.location.reload();
                        }
                        App.Util.processarSucessoRequisicaoAjax(e, dados, status, jqXHR);
                        if (dados.redirecionarPara) {
                            setTimeout(function() {
                                window.location.href = dados.redirecionarPara;
                            }, 3800);
                        }
                    }
                })
                .fail((jqXHR, status, error) => {
                    if (callbackErro) {
                        callbackErro(e, jqXHR, status, error);
                    } else {
                        App.Util.processarErroRequisicaoAjax(e, jqXHR, status, error);
                    }
                })
                .always(() => {
                    if (callbackCompleto) {
                        callbackCompleto(e);
                    } else {
                        App.Util.completarProcessoRequisicaoAjax(e);
                        App.Util.ocultarModalPaginaCarregando();
                    }
                });
        }

        public postModal(e: JQueryEventObject, modal: any = false) {
            e.preventDefault();
            if (modal) {
                $(modal).modal('hide');
            }
            App.Util.exibirModalPaginaCarregando();
            var url: string = $(e.target).attr("action");
            var dados: any = this.convertFormToJSON($(e.target));
            App.Util.preparaCabecalhoRequisicaoAjax();
            var vaiRedirecionar: boolean = false;
            $.post(url, JSON.stringify(dados))
                .done((dados, status, jqXHR) => {
                    if (dados.recarregar == true) {
                        vaiRedirecionar = true;
                        window.location.reload();
                    }
                    App.Util.processarSucessoRequisicaoAjax(e, dados, status, jqXHR);

                    if (dados.redirecionarPara) {
                        vaiRedirecionar = true;
                        setTimeout(function() {
                            window.location.href = dados.redirecionarPara;
                        }, 3500);
                    }
                })
                .fail((jqXHR, status, error) => {
                    App.Util.processarErroRequisicaoAjax(e, jqXHR, status, error);
                    if (modal) {
                        $(modal).modal('show');
                    }
                })
                .always(() => {
                    App.Util.completarProcessoRequisicaoAjax(e);
                    if (!vaiRedirecionar) {
                        App.Util.ocultarModalPaginaCarregando();
                    }
                });
        }

        public postAcaoSemCabecalhoGlobal(url: string, dadosEnviados: any, callbackSucesso?: any, parametroCallback?: any) {
            App.Util.exibirModalPaginaCarregando();
            var vaiRedirecionar: boolean = false;
            $.ajax({
                url: url,
                type: 'POST',
                contentType: 'application/json',
                data: JSON.stringify(dadosEnviados),
                beforeSend: function(jqXHR, settings) {
                    jqXHR.setRequestHeader('X-CSRF-TOKEN', $('meta[name=csrf-token]').attr("content"));
                },
                success: (dados) => {
                    if (callbackSucesso) {
                        var objParametros = { dadosEnviados: dadosEnviados, dadosRetornados: dados, status: 200 }
                        callbackSucesso(parametroCallback ? parametroCallback : objParametros);
                    }
                    if (dados.recarregar == true) {
                        window.location.reload();
                        return;
                    }
                    
                    if (dados.hasOwnProperty("mensagem")) {
                        App.Util.processarSucessoRequisicaoAjax(null, dados, 200, null);
                    }

                    if (dados.redirecionarPara) {
                        vaiRedirecionar = true;
                        setTimeout(function() {
                            window.location.href = dados.redirecionarPara;
                        }, 3500);
                    }
                    if (!vaiRedirecionar) {
                        App.Util.ocultarModalPaginaCarregando();
                    }
                },
                error: (jqXHR, status, error) => {
                    if (!vaiRedirecionar) {
                        App.Util.ocultarModalPaginaCarregando();
                    }
                }
            });
        }

        public postAcao(url: string, dadosEnviados: any, callbackSucesso?: any, parametroCallback?: any) {
            App.Util.exibirModalPaginaCarregando();
            App.Util.preparaCabecalhoRequisicaoAjax();
            var vaiRedirecionar: boolean = false;
            $.post(url, JSON.stringify(dadosEnviados))
                .done((dados, status, jqXHR) => {
                    if (callbackSucesso) {
                        var objParametros = { dadosEnviados: dadosEnviados, dadosRetornados: dados, status: status, jqXHR: jqXHR }
                        callbackSucesso(parametroCallback ? parametroCallback : objParametros);
                    }
                    if (dados.recarregar == true) {
                        window.location.reload();
                    }
                    if (dados.hasOwnProperty('mensagem')) {
                        App.Util.processarSucessoRequisicaoAjax(null, dados, status, jqXHR);
                    }

                    if (dados.redirecionarPara) {
                        vaiRedirecionar = true;
                        setTimeout(function() {
                            window.location.href = dados.redirecionarPara;
                        }, 3500);
                    }
                })
                .fail((jqXHR, status, error) => {
                })
                .always(() => {
                    if (!vaiRedirecionar) {
                        App.Util.ocultarModalPaginaCarregando();
                    }
                });
        }

        public postAcaoSemResposta(url: string, dados: any) {
            $.ajax({
                url: url,
                type: 'POST',
                contentType: 'application/json',
                data: JSON.stringify(dados),
                beforeSend: function(jqXHR, settings) {
                    jqXHR.setRequestHeader('X-CSRF-TOKEN', $('meta[name=csrf-token]').attr("content"));
                }
            });
        }

        public postAcaoModal(url: string, dados: any, modal: any = false) {
            App.Util.exibirModalPaginaCarregando();
            App.Util.preparaCabecalhoRequisicaoAjax();
            var vaiRedirecionar: boolean = false;
            if (modal) {
                $(modal).modal('hide');
            }
            $.post(url, JSON.stringify(dados))
                .done((dados, status, jqXHR) => {
                    if (dados.recarregar == true) {
                        vaiRedirecionar = true;
                        window.location.reload();
                    }
                    App.Util.processarSucessoRequisicaoAjax(null, dados, status, jqXHR);

                    if (dados.redirecionarPara) {
                        vaiRedirecionar = true;
                        setTimeout(function() {
                            window.location.href = dados.redirecionarPara;
                        }, 3500);
                    }
                })
                .fail((jqXHR, status, error) => {
                    if (modal) {
                        $(modal).modal('show');
                    }
                })
                .always(() => {
                    if (!vaiRedirecionar) {
                        App.Util.ocultarModalPaginaCarregando();
                    }
                });
        }

        public recarregarNaoLogado(redirecionarPara: string) {
            $.get(this.urlAutenticado)
                .done((dados, status, jqXHR) => {
                    if (!dados.autenticado) {
                        if (redirecionarPara) {
                            window.location.href = redirecionarPara;
                        } else {
                            window.location.reload(true);
                        }
                        return;
                    }
                });
        }

        public convertFormToJSON(form: JQuery) {
            var array = $(form).serializeArray();
            var json: any = {};
            var arr: any = {};
            var campoArrayContexto: string = '';
            $.each(array, function() {
                var contexto: ICamposForm = this;
                if ($('[name=' + contexto.name.replace("[]", "") + ']').hasClass('nosubmit')) {
                    return;
                }
                if (contexto.name.indexOf('[') > -1) {
                    var nomeFormatadoAux = contexto.name.split("[");
                    var nomeFormatado = nomeFormatadoAux[0];
                    var index = nomeFormatadoAux[1].replace("]", "");
                    if (!$.isArray(arr[nomeFormatado])) {
                        arr[nomeFormatado] = [];
                    }
                    arr[nomeFormatado].push($('[name=' + nomeFormatado + ']').hasClass('numero') ? App.Util.obterInteiroDaString(contexto.value) || '' : contexto.value || '');
                    campoArrayContexto = nomeFormatado;
                } else {
                    json[contexto.name] = $('[name=' + contexto.name + ']').hasClass('numero') ? App.Util.obterInteiroDaString(contexto.value) || '' : contexto.value || '';
                }
            });
            $(form).find("input[type=checkbox]").each(function(i, e) {
                json[$(e).attr("name")] = $(e).is(':checked');
            });
            if (!$.isEmptyObject(arr)) {
                json[campoArrayContexto] = arr[campoArrayContexto];
            }
            arr = [];

            return json;
        }
    }
}
