/// <reference path="../typings/index.d.ts" />
/// <reference path="Util.d.ts" />
declare var dados: any;
declare var Switchery: any;
declare var formatOptionsSelect: any;
namespace App {
    export class Util {
        public static locate: string = 'pt-br';
        public static versaoFifaAtual: string = '20';
        public static tipoExibicaoValidacaoFormularioSuperior: string = 'superior';
        public static tipoExibicaoValidacaoFormularioCampoACampo: string = 'campoacampo';
        public static tipoExibicaoValidacaoFormulario: string;
        public static tipoAlertaSuperior: string = 'superior';
        public static tipoAlertaInferior: string = 'inferior';

        public static corVerdePadrao: string = "#31C68B";

        public static tipoMensagemInfo: string = "info";
        public static tipoMensagemSuccess: string = "success";
        public static tipoMensagemDanger: string = "danger";
        public static tipoMensagemWarning: string = "warning";

        public static mensagensRetorno: string = "";
        public static contextoFormAtual: JQueryEventObject;
        public static ehValidacaoCampoACampo: boolean;
        public static exibirSweetAlerta: any = null;
        public static usuario: IUsuario = null;
        public static config: IConfig = null;
        public static exibirDialogoSweetAlerta: any = null;
        public static exibirAlertaNotificacao: any = null;
        public static alertaNotificacao: IAlertaNotificacaoToastrOptions = null;
        private static dados: any = [];
        private static ehSite: boolean;
        private static ehApp: boolean;

        public static setLocate(locate: string) {
            App.Util.locate = locate;
        }
        public static setSweetAlerta(exibirSweetAlerta: string) {
            App.Util.exibirSweetAlerta = exibirSweetAlerta;
        }
        public static setDialogoSweetAlerta(exibirDialogoSweetAlerta: string) {
            App.Util.exibirDialogoSweetAlerta = exibirDialogoSweetAlerta;
        }
        public static setAlertaNotificacao(exibirAlertaNotificacao: string) {
            App.Util.exibirAlertaNotificacao = exibirAlertaNotificacao;
        }
        public static setConfig(dadosConfig: any) {
            App.Util.config = dadosConfig;
            App.Util.usuario = dadosConfig.usuario;
        }

        private static processarContexto() {
            var contexto = $('body').data('contexto');
            this.ehSite = contexto == 'site';
            this.ehApp = contexto == 'app';
        }

        public static iniciarModulo(nomeModulo: string, incluirConfig = false) {
            if (incluirConfig) {
                App.Util.exibirModalPaginaCarregando();
                $.ajax({
                    url: "/46_DAxPx-ajwLyzlfnXxWQ/KUULtGnX0hwaqvRx39le1A",
                    type: 'GET',
                    contentType: 'application/json',
                    beforeSend: function (jqXHR, settings) {
                        jqXHR.setRequestHeader('X-CSRF-TOKEN', $('meta[name=csrf-token]').attr("content"));
                    },
                    success: (dadosConfig) => {
                        App.Util.setConfig(dadosConfig)
                        App.Util.continuarInicializacao(nomeModulo);
                    },
                    error: (jqXHR, textStatus, errorThrown) => {
                    }
                });
            } else {
                App.Util.continuarInicializacao(nomeModulo);
            }
        }

        private static continuarInicializacao(nomeModulo: string): void {
            if (nomeModulo != "") {
                (App.Modulos as any)[nomeModulo].inicializar();
            }
            this.processarContexto();
            this.tipoExibicaoValidacaoFormulario = this.tipoExibicaoValidacaoFormularioCampoACampo;
            this.ehValidacaoCampoACampo = App.Util.tipoExibicaoValidacaoFormulario == App.Util.tipoExibicaoValidacaoFormularioCampoACampo;
            $('.alerta-barra-acoes .close').on('click', (e) => { this.aoOcultarAlertaAcoes(e); });
            $('.input-group.padrao > input').on('focus', (e) => { this.aoFocarCampoGroupAddon(e); });
            $('.input-group.padrao > input').on('focusout', (e) => { this.aoDesfocarCampoGroupAddon(e); });
            $('.fechar-notificacao').on('click', (e) => { this.marcarNotificacaoComoLida(e); });
            $('.ver-telefone').on('click', (e) => { this.aoVerTelefone(e); });

            this.alertaNotificacao = new AlertaNotificacaoToastr();
            this.alertaNotificacao.inicializarComponente();
            this.processarAlertas();

            this.inicializarHoverDropDown();
            this.inicializarSelect2Config();
            this.inicializarToastr();
            this.inicializarPluginsData();
            if ($(".video-modal-como-utilizar").length > 0) {
                var video = new Video();
                video.inicializar($(".video-modal-como-utilizar"));
            }
        }

        private static aoVerTelefone(e: JQueryEventObject): void {
            console.log("ver tel")
            $('.tel-minimized').hide()
            $('.tel-maximized').show()
        }
        private static aoFocarCampoGroupAddon(e: JQueryEventObject): void {
            $(e.target).parents(".input-group").find('.input-group-addon').addClass("focus");
        }
        private static aoDesfocarCampoGroupAddon(e: JQueryEventObject): void {
            $(e.target).parents(".input-group").find('.input-group-addon').removeClass("focus");
        }
        private static processarAlertas(): void {
            if (App.Util.exibirSweetAlerta != null) {
                var mensagem: ISweetAlerta = App.Util.exibirSweetAlerta;
                App.Util.exebirSweetAlerta(mensagem.titulo, mensagem.mensagem, mensagem.tipoAlerta, mensagem.dadosAlerta);
            }
            if (App.Util.exibirDialogoSweetAlerta != null) {
                var mensagemDialogo: IDialogoSweetAlerta = App.Util.exibirDialogoSweetAlerta;
                App.Util.exibirSweetConfirmacaoAjax(mensagemDialogo.urlCallback, mensagemDialogo.urlCallbackCancela, mensagemDialogo.titulo, mensagemDialogo.mensagem, mensagemDialogo.textoBotaoConfirmacao);
            }

            if (App.Util.exibirAlertaNotificacao != null) {
                var notificacao: IAlertaNotificacao = App.Util.exibirAlertaNotificacao;
                this.exibirAlertaToastr(notificacao);

            }
        }

        public static exibirAlertaToastr(notificacao: IAlertaNotificacao): void {
            switch (notificacao.tipo) {
                case 'success':
                    this.alertaNotificacao.exibirSucesso(notificacao.titulo, notificacao.mensagem);
                    break;
                case 'info':
                    this.alertaNotificacao.exibirInfo(notificacao.titulo, notificacao.mensagem);
                    break;
                case 'warning':
                    this.alertaNotificacao.exibirAtencao(notificacao.titulo, notificacao.mensagem);
                    break;
                case 'error':
                    this.alertaNotificacao.exibirErro(notificacao.titulo, notificacao.mensagem);
                    break;
            }
        }

        public static inicializarPluginsData(): void {
            $('.datepicker-input').datepicker({
                format: 'dd/mm/yyyy',
                language: 'pt-BR',
                todayBtn: "linked",
                keyboardNavigation: false,
                forceParse: false,
                calendarWeeks: true,
                autoclose: true
            });
        }

        public static inicializarJqueryValidate(): void {
            $.extend(jQuery.validator.messages, {
                required: "Este campo &eacute; obrigatório.",
                remote: "Por favor, corrija este campo.",
                email: "Por favor, forne&ccedil;a um endere&ccedil;o eletr&ocirc;nico v&aacute;lido.",
                url: "Por favor, forne&ccedil;a uma URL v&aacute;lida.",
                date: "Por favor, forne&ccedil;a uma data v&aacute;lida.",
                dateISO: "Por favor, forne&ccedil;a uma data v&aacute;lida (ISO).",
                number: "Por favor, forne&ccedil;a um n&uacute;mero v&aacute;lido.",
                digits: "Por favor, forne&ccedil;a somente d&iacute;gitos.",
                creditcard: "Por favor, forne&ccedil;a um cart&atilde;o de cr&eacute;dito v&aacute;lido.",
                equalTo: "Os valores dos campos não coincidem.",
                accept: "Por favor, forne&ccedil;a um valor com uma extens&atilde;o v&aacute;lida.",
                maxlength: jQuery.validator.format("Por favor, forne&ccedil;a n&atilde;o mais que {0} caracteres."),
                minlength: jQuery.validator.format("Por favor, forne&ccedil;a ao menos {0} caracteres."),
                rangelength: jQuery.validator.format("Por favor, forne&ccedil;a um valor entre {0} e {1} caracteres de comprimento."),
                range: jQuery.validator.format("Por favor, forne&ccedil;a um valor entre {0} e {1}."),
                max: jQuery.validator.format("Por favor, forne&ccedil;a um valor menor ou igual a {0}."),
                min: jQuery.validator.format("Por favor, forne&ccedil;a um valor maior ou igual a {0}.")
            });

            $.validator.addMethod("dateBR", function (value, element) {
                if (value == null || value == "") {
                    return true;
                }
                if (value.length != 10) return false;
                // verificando data
                var data = value;
                var dia = data.substr(0, 2);
                var barra1 = data.substr(2, 1);
                var mes = data.substr(3, 2);
                var barra2 = data.substr(5, 1);
                var ano = data.substr(6, 4);
                if (data.length != 10 || barra1 != "/" || barra2 != "/" || isNaN(dia) || isNaN(mes) || isNaN(ano) || dia > 31 || mes > 12) return false;
                if ((mes == 4 || mes == 6 || mes == 9 || mes == 11) && dia == 31) return false;
                if (mes == 2 && (dia > 29 || (dia == 29 && ano % 4 != 0))) return false;
                if (ano < 1900) return false;
                return true;
            }, "Informe uma data válida");  // Mensagem padrão

            $.validator.addMethod("dataMenor", function (value, element, params) {
                var dataInicio = $(params).val();
                var dataFim = value;
                var partsInicio = dataInicio.split("/");
                var partsFim = dataFim.split("/");
                return this.optional(element) || new Date(Number(partsFim[2]), Number(partsFim[1]) - 1, Number(partsFim[0])) > new Date(Number(partsInicio[2]), Number(partsInicio[1]) - 1, Number(partsInicio[0]));
            }, 'Data deve ser maior que data inicial.');

            $.validator.addMethod("emailValido", function (value, element) {
                return this.optional(element) || /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@(?:\S{1,63})$/.test(value);
            }, 'Informe um e-mail válido.');

            $.validator.addMethod("noEmpty", function (value, element) {
                return !(!$.trim(value));
            }, "Este campo &eacute; obrigatório.");

            $.validator.addMethod("requireOne", function (value: any, element: any) {
                return $('.require-one:checkbox:checked').length > 0
            }, "Você precisa escolher um item.");
        }

        public static exebirSweetAlerta(titulo: string, texto: string, tipo: string = null, dadosAlerta: any = null): void {
            var dados: ISwal = {
                title: titulo,
                text: texto,
                confirmButtonColor: "#22B87E",
                confirmButtonText: "Fechar"
            }
            if (tipo) {
                dados.type = tipo;
            }
            if (dadosAlerta && dadosAlerta.hasOwnProperty("html")) {
                dados.html = dadosAlerta.html;
            }
            swal(dados);
        }

        public static exibirSweetConfirmacaoAjax(urlCallback: string, urlCallbackCancela: string, titulo: string, texto: string, textoBotaoConfirmacao: string = "Sim, continuar!", corBotaoConfirmacao: string = "#DD6B55"): void {
            swal({
                title: titulo,
                text: texto,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: corBotaoConfirmacao,
                confirmButtonText: textoBotaoConfirmacao,
                cancelButtonText: "cancelar",
                closeOnConfirm: true
            },
                function (isConfirm: boolean) {
                    var ajax: IAjax;
                    ajax = new App.Ajax();
                    var dados: any = {};
                    if (isConfirm) {
                        ajax.postAcao(urlCallback, dados);
                    } else {
                        ajax.postAcao(urlCallbackCancela, dados);
                    }
                });
        }

        public static exebirSweetConfirmacao(e: JQueryEventObject, titulo: string, texto: string, textoBotaoConfirmacao: string = "Sim, continuar!", metodoAexecutarAposConfirmacao: any, corBotaoConfirmacao: string = "#DD6B55"): void {
            swal({
                title: titulo,
                text: texto,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: corBotaoConfirmacao,
                confirmButtonText: textoBotaoConfirmacao,
                cancelButtonText: "cancelar",
                closeOnConfirm: true
            },
                () => {
                    metodoAexecutarAposConfirmacao(e);
                });
        }

        public static processarSucessoRequisicaoAjax(e: JQueryEventObject, dados: IRespostaAjax, status: any, jqXHR: any): void {
            if (dados.hasOwnProperty("tipo")) {
                this.exibirAlertaToastr(dados);
            } else {
                /**
                 * Para exibir uma barra simples de alerta
                 * App.Util.exibirMensagemAlerta(dados.mensagem, App.Util.tipoMensagemSuccess, App.Util.tipoAlertaInferior);
                 */
                App.Util.exebirSweetAlerta(dados.titulo, dados.mensagem, "success", dados.dadosAlerta);
                if (dados.resetarCamposFormulario) {
                    App.Util.limparCamposFormulario(e);
                }

                if (e) {
                    App.Util.limparErrosDoFormulario(e);
                    App.Util.ocultarMensagemFormulario(e);
                }
            }
        }

        public static processarErroRequisicaoAjax(e: JQueryEventObject, jqXHR: any, status: any, error: any): void {
            App.Util.exibirMensagemFormulario(e, jqXHR);
        }

        public static completarProcessoRequisicaoAjax(e: JQueryEventObject): void {

        }

        public static preparaCabecalhoRequisicaoAjax(): void {
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name=csrf-token]').attr("content")
                },
                contentType: 'application/json'
            });
        }

        /**
         * Substitui determinado valor por outro valor escolhido
         * dentro do texto informado.
         * @param string find Valor a ser encontrado para ser substituído
         * @param string replace Valor de substituição
         * @param string text Texto informado para receber a subtituição.
         * @returns string texto com valores substituídos.
         */
        public static replaceAll(find: string, replace: string, text: string): string {
            return text.replace(new RegExp(find, 'g'), replace);
        }

        public static validaEmail(email: string): boolean {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }

        /**
         * Remove caracteres especiais e espaços retornando uma string
         * limpa
         */
        public static removerCaracteresEspeciais(valor: string): string {
            valor = valor.replace(/[ÀÁÂÃÄÅ]/, "A");
            valor = valor.replace(/[àáâãäå]/, "a");
            valor = valor.replace(/[ÈÉÊË]/, "E");
            valor = valor.replace(/[Ç]/, "C");
            valor = valor.replace(/[ç]/, "c");

            return valor.replace(/[^a-z0-9]/gi, '');
        }

        public static validaData(data: string): boolean {
            var arrData: any = data.split("/");
            var dia: any = ("00" + arrData[0]).substring(arrData[0].length);
            var mes: any = ("00" + arrData[1]).substring(arrData[1].length);
            var ano: any = arrData[2];
            if (dia.toString().trim() !== '' && mes.toString().trim() !== '' && ano.toString().trim() !== '') {
                //Verifica se o ano está correto
                if (ano.length == 4 && ano > 1900 && ano < 2100) {
                    // Verificando o intervalo permitido para os valores dos meses e dias
                    if (dia > 0 && dia <= 31 && mes > 0 && mes <= 12) {
                        // Verifica os meses que posuem dia 30 dias
                        if ((mes == 4 || mes == 6 || mes == 9 || mes == 11) && dia > 30) {

                            return false;
                        }
                        // caso seja mês 2 verifica se o ano é bissexto
                        if (mes == 2) {
                            //se for bissexto
                            if (ano % 4 == 0 && (ano % 100 != 0 || ano % 400 == 0)) {
                                // Se for bissexto pode o dia ser no máximo 29
                                if (dia > 29)
                                    return false;
                                // se não for bisexto o dia pode ser no máximo 28
                            } else if (dia > 28) {

                                return false;
                            }
                        }
                    } else {
                        return false;
                    }
                    // Data válida
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }

        public static adicionarProgressoElemento(elementoExibir: JQuery, configuracoes: Object = Util.obterConfiguracoesElementoProgresso()) {
            var container: HTMLElement = elementoExibir[0];
            var spinner = new Spinner(configuracoes).spin(container);
            return spinner;
        }

        public static obterConfiguracoesElementoProgresso(): SpinnerOptions {
            var configuracoes: SpinnerOptions = {
                lines: 9 // The number of lines to draw
                , length: 0 // The length of each line
                , width: 3 // The line thickness
                , radius: 46 // The radius of the inner circle
                , scale: 1 // Scales overall size of the spinner
                , corners: 0.9 // Corner roundness (0..1)
                , color: '#fff' // #rgb or #rrggbb or array of colors
                , opacity: 0.25 // Opacity of the lines
                , rotate: 40 // The rotation offset
                , direction: 1 // 1: clockwise, -1: counterclockwise
                , speed: 1.6 // Rounds per second
                , trail: 60 // Afterglow percentage
                , fps: 20 // Frames per second when using setTimeout() as a fallback for CSS
                , zIndex: 2e9 // The z-index (defaults to 2000000000)
                , className: 'spinner' // The CSS class to assign to the spinner
                , top: '49%' // Top position relative to parent
                , left: '50%' // Left position relative to parent
                , shadow: false // Whether to render a shadow
                , hwaccel: false // Whether to use hardware acceleration
                , position: 'absolute' // Element positioning
            };
            return configuracoes;
        }

        public static exibirModalPaginaCarregando(): void {
            App.Util.adicionarProgressoElemento($('#progressoPagina'));
            $('#carregando').modal('show');
        }

        public static ocultarModalPaginaCarregando(): void {
            $('#carregando').modal('hide');
            setTimeout(function () { $('#carregando').modal('hide'); }, 500);

        }

        public static exibirMensagemAlerta(mensagem: string, tipoMensagem: string = App.Util.tipoMensagemInfo, tipoAlerta: string = App.Util.tipoAlertaSuperior, fecharMensagemAutomaticamente: boolean = true): void {
            var selector = '#alerta-barra-acoes-' + tipoAlerta;
            $(selector + ' .alert').addClass('alert-' + tipoMensagem);
            $(selector + ' .mensagem').html(mensagem);
            $(selector).removeClass('hide');
            if (fecharMensagemAutomaticamente) {
                setTimeout(function () { App.Util.ocultarMensagemAlerta() }, 7000);
            }
        }

        public static ocultarMensagemAlerta(e: JQueryEventObject = null): void {
            $('.alerta-barra-acoes .close').click();
        }

        public static aoOcultarAlertaAcoes(e: JQueryEventObject): void {
            var elemento = $(e.target).parents('.alerta-barra-acoes');
            $(elemento).find('.alert').removeClass().addClass('alert');
            $(elemento).find('.mensagem').html('');
            $(elemento).addClass('hide');
        }

        public static limparCamposFormulario(e: JQueryEventObject) {
            $(e.target).trigger("reset");
        }

        public static ocultarMensagemFormulario(e: JQueryEventObject) {
            var alerta = $(e.target).find('.alert-ajax');
            var conteudo = alerta.find('ul');
            conteudo.html('');
            alerta.addClass('hidden');
        }

        public static limparErrosDoFormulario(e: JQueryEventObject) {
            var elementoFormulario = $(e.target);
            elementoFormulario.find('.form-group').removeClass('has-error');
            elementoFormulario.find('.error').addClass('hide');
        }

        public static atribuirErroAoCampo(campo: string, valor: string) {
            var elementoCampo = $('[name=' + campo + ']');
            if (elementoCampo.length > 0) {
                var formGroup = elementoCampo.parents('.form-group');
                formGroup.addClass('has-error');
                var error = formGroup.find('.error');
                error.removeClass('hide').css('display', 'block');
                error.html(valor);
            }
        }
        public static atribuirErroAoElementoCampo(campo: JQuery, mensagem: string) {
            campo.parent(".form-group").addClass('has-error').removeClass('hide');
            campo.parent(".form-group").find('.error').removeClass('hide').html(mensagem);
        }
        public static atribuirErroAoCampoChosen(campo: string, valor: string) {
            $(campo).addClass('has-error');
            var erro: any = $(campo).find('.error');
            erro.removeClass('hide').css('display', 'block');
            erro.html(valor);
        }

        public static exibirMensagemFormulario(e: JQueryEventObject, jqXHR: any) {
            var erros: any[] = jqXHR.responseJSON;
            if (this.ehValidacaoCampoACampo) {
                App.Util.limparErrosDoFormulario(e);
                $.each(erros, App.Util.encontrarMensagensDeResposta);
                return;
            }

            var alerta = $(e.target).find('.alert-ajax');
            var conteudo = alerta.find('ul');
            conteudo.html('');
            $.each(erros, App.Util.encontrarMensagensDeResposta);
            conteudo.append(App.Util.mensagensRetorno);
            App.Util.mensagensRetorno = "";
            alerta.animate({ scrollTop: 0 }, 500).removeClass('hidden');
        }

        private static encontrarMensagensDeResposta(chave: any, valor: any) {
            if (valor instanceof Object) {
                var campo = chave;
                $.each(valor, function (chave, valor) {
                    if (App.Util.ehValidacaoCampoACampo) {
                        App.Util.atribuirErroAoCampo(campo, valor);
                    }
                    App.Util.encontrarMensagensDeResposta(chave, valor);
                });
                return;
            }

            App.Util.mensagensRetorno += '<li>' + valor + '</li>';
        }

        public static inicializarCampoChosen(seletor: string, campoCargos: string, seletorProximoFocu: string, maximoOpcoesSelecionadas: number = 5, textoNaoResultado: string = "Dado não encontrado", funcaoAposLimiteNaoPermitido: any) {
            $(seletor).chosen({ width: "100%", max_selected_options: maximoOpcoesSelecionadas, disable_search_threshold: maximoOpcoesSelecionadas, no_results_text: textoNaoResultado })
                .change(function (event) {
                    if (event.target == this) {
                        $('input[name=' + campoCargos + ']').val($(this).val());
                    }
                });

            $(seletor).bind("chosen:maxselected", () => {
                if ($(seletorProximoFocu).length > 0) {
                    $(seletorProximoFocu).focus();
                }
                funcaoAposLimiteNaoPermitido();
            });
        }

        public static obterQuantidadeItensAdicionados(seletor: string): number {
            var quantidade: number = 0;
            $(seletor + " .itens-selecionados").find('.item-removivel').each(function () {
                quantidade += 1;
            });

            return quantidade;
        }

        public static removerItem(e: JQueryEventObject, seletor: string) {
            e.preventDefault();
            $(e.target).parents('.item-removivel').remove();
        }

        public static ehCampoTextoVazio(campo: JQuery) {
            return !$.trim(campo.val());
        }
        public static empty(param: any) {
            var variavel = $.trim(param);
            return typeof variavel == 'undefined' || variavel === null || variavel == "";
        }
        public static ehCampoSelectVazio(campo: JQuery) {
            return campo.val() == "";
        }

        public static ehVariavelValidaNaoVazia(variavel: any) {
            if (typeof variavel == 'undefined' || variavel === null || isNaN(variavel) || variavel.toString().trim() == "") {
                return false;
            }
            return true;
        }

        public static resetarErrosForm(elemento: JQuery) {
            elemento.find('form span.error').html('').css('display', 'none');
            elemento.find('form label.error').html('').remove();
            elemento.find('form .form-control.error').removeClass('error');
        }

        public static validacaoPadrao(form: any) {
            form.validate({
                errorElement: 'span'
            });
        }
        public static validacaoPadraoRealocandoErro(form: any, seletor: string) {
            form.validate({
                errorElement: 'span',
                errorPlacement: (error: any, element: any) => {
                    error.insertAfter(seletor);
                }
            });
        }
        public static inicializarHoverDropDown() {
            $('.btn-group.tipo-hover').hover(function () {
                $(this).find('.dropdown-menu').stop(true, true).fadeIn(400);
            }, function () {
                $(this).find('.dropdown-menu').stop(true, true).fadeOut(400);
            });
        }
        public static refreshAnimation(wizard: any, index: any) {
            var total = wizard.find('.nav li').length;
            var li_width = 100 / total;

            var total_steps = wizard.find('.nav li').length;
            var move_distance = wizard.width() / total_steps;
            var index_temp = index;
            var vertical_level = 0;

            var mobile_device = $(document).width() < 600 && total > 3;

            if (mobile_device) {
                move_distance = wizard.width() / 2;
                index_temp = index % 2;
                var li_width = 50;
            }

            wizard.find('.nav li').css('width', li_width + '%');

            var step_width = move_distance;
            move_distance = move_distance * index_temp;

            var current = index + 1;

            if (current == 1 || (mobile_device == true && (index % 2 == 0))) {
                move_distance -= 8;
            } else if (current == total_steps || (mobile_device == true && (index % 2 == 1))) {
                move_distance += 8;
            }

            if (mobile_device) {
                var vertical_level: number = index / 2;
                vertical_level = vertical_level * 38;
            }

            wizard.find('.moving-tab').css('width', step_width);
            $('.moving-tab').css({
                'transform': 'translate3d(' + move_distance + 'px, ' + vertical_level + 'px, 0)',
                'transition': 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)'

            });
        }
        public static parseMinutosParaMilisegundos(minutos: number) {
            return minutos * 60000;
        }

        public static parseMilisegundosParaSegundos(milisegundos: number) {
            return milisegundos / 1000;
        }

        public static parseSegundosParaMilisegundos(segundos: number) {
            return segundos * 1000;
        }

        public static inArray(valor: any, array: any) {
            return $.inArray(valor, array) > -1
        }
        public static inicializarSelect2Config() {
            if (this.ehApp) {
                $(".select-img").select2({
                    templateResult: formatOptionsSelect,
                    templateSelection: formatOptionsSelect
                });
            }

        }
        public static inicializarToastr() {
            toastr.options = {
                "closeButton": false,
                "debug": false,
                "newestOnTop": false,
                "progressBar": false,
                "positionClass": "toast-bottom-center",
                "preventDuplicates": false,
                "onclick": null,
                "showDuration": "300",
                "hideDuration": "1000",
                "timeOut": "5000",
                "extendedTimeOut": "1000",
                "showEasing": "swing",
                "hideEasing": "linear",
                "showMethod": "fadeIn",
                "hideMethod": "fadeOut"
            }

        }

        public static obterInteiroDaString(str: string): number {
            return parseInt(str.replace(/[^0-9]/gi, ''));
        }

        public static obterSomenteNumerosDaString(str: string): any {
            return str.replace(/[^0-9]/g, '');
        }

        public static getRandomInt(min: number, max: number) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }

        public static sleep(milliseconds: number) {
            var start = new Date().getTime();
            for (var i = 0; i < 1e7; i++) {
                if ((new Date().getTime() - start) > milliseconds) {
                    break;
                }
            }
        }

        public static redirecionarNaoAutenticado(urlAutenticado: string, urlRedirecionar: string) {
            $.get(urlAutenticado)
                .done((dados, status, jqXHR) => {
                    if (!dados.autenticado) {
                        window.location.href = urlRedirecionar;
                        return;
                    }
                });
        }

        public static isset(accessor: any) {
            try {
                return typeof accessor !== 'undefined';
            } catch (e) {
                return false;
            }
        }

        public static getIndiceArrayByAssetId(array: any, assetId: number | string) {
            var index = array.map(function (item: any) { return item.assetId; }).indexOf(assetId);
            return index;
        }
        public static getItemArrayByAssetId(array: any, assetId: number | string) {
            var index = array.map(function (item: any) { return item.assetId; }).indexOf(assetId);
            return array[index];
        }
        public static getItemArrayByType(array: any, type: number | string) {
            var index = array.map(function (item: any) { return item.type; }).indexOf(type);
            return array[index];
        }

        public static arrayRemove(arr: any, value: string) {
            return arr.filter(function (ele: any) {
                return ele != value;
            });
        }
        public static arrayRemoveByResourceId(arr: any, value: string) {
            return arr.filter(function (ele: any) {
                return ele.resourceId != value;
            });
        }
        public static arrayExistByResourceId(arr: any, value: string) {
            return arr.filter(function (ele: any) {
                return ele.resourceId == value;
            }).length > 0;
        }
        public static getIndiceArrayByResourceId(array: any, resourceId: number | string) {
            var index = array.map(function (item: any) { return item.resourceId; }).indexOf(resourceId);
            return index;
        }
        public static setArrayStorage(key: string, arr: any) {
            localStorage.setItem(key, JSON.stringify(arr));
        }
        public static getArrayStorage(key: string) {
            var storedArray = localStorage.getItem(key);
            if (this.empty(storedArray)) {
                return null;
            } else {
                return JSON.parse(storedArray);
            }
        }
        public static exibirMensagemAlertaSwalPadrao(title: string, mensagem: string, tipo: string = "error", confirmButtonText: string = "OK", cancelButtonText: string = "Fechar", confirmButtonColor: string = "#FB6340", showCancelButton: boolean = true, urlRedirectClickConfirm: string = null, footer: string = null, contaEaJaVinculada: any = null) {
            var objSwal: any = {
                title: title,
                html: mensagem,
                type: tipo,
                confirmButtonText: confirmButtonText,
                confirmButtonColor: confirmButtonColor,
                showCancelButton: showCancelButton,
                cancelButtonText: cancelButtonText
            }
            if (footer) {
                objSwal.footer = footer;
            }
            swal(objSwal).then((result: any) => {
                if (result.hasOwnProperty('value') && result.value && urlRedirectClickConfirm) {
                    window.location.href = urlRedirectClickConfirm;
                    return;
                }

            });
        }

        public static existeIndexDataTable(dt: any, index: number) {
            return dt.rows().count() > index;
        }

        public static alterarLoadingProcessamento(elLoading: JQuery, porcentagem: any) {
            var corpoPorcentagem = elLoading.find('.corpo');
            corpoPorcentagem.removeClass('p' + corpoPorcentagem.data('porcentagem'));
            corpoPorcentagem.data('porcentagem', porcentagem);
            corpoPorcentagem.attr('data-porcentagem', porcentagem);
            corpoPorcentagem.addClass('p' + porcentagem);
            corpoPorcentagem.find('.porcentagem').html(porcentagem + '%');
        }

        public static exibirLoadingProcessamento(elLoading: JQuery, porcentagem: any) {
            var corpoPorcentagem = elLoading.find('.corpo');
            corpoPorcentagem.removeClass('p' + corpoPorcentagem.data('porcentagem'));
            corpoPorcentagem.data('porcentagem', porcentagem);
            corpoPorcentagem.attr('data-porcentagem', porcentagem);
            corpoPorcentagem.addClass('p' + porcentagem);
            corpoPorcentagem.find('.porcentagem').html(porcentagem + '%');
            elLoading.show();
        }

        public static ocultarLoadingProcessamento(elLoading: JQuery) {
            var corpoPorcentagem = elLoading.find('.corpo');
            corpoPorcentagem.removeClass('p' + corpoPorcentagem.data('porcentagem'));
            corpoPorcentagem.data('porcentagem', 0);
            corpoPorcentagem.attr('data-porcentagem', 0);
            corpoPorcentagem.addClass('p' + 0);
            corpoPorcentagem.find('.porcentagem').html(0 + '%');
            elLoading.hide();
        }

        public static marcarNotificacaoComoLida(e: JQueryEventObject): void {
            var ajax = new App.Ajax();
            var dados: any = { id: $(e.currentTarget).data('idNotificacao') };
            ajax.postAcaoSemResposta($(e.currentTarget).data('urlNotificacao'), dados);
        }
    }

}
